<template>
    <footer>
        <div class="footer-content custom-container">
            <div class="footer-top">
                <a href="/" class="footer-left">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.49997 12.4L5 17.7999"
                            stroke="#fbfbfb"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.49997 12.4L5 7"
                            stroke="#fbfbfb"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <line
                            x1="18"
                            y1="18"
                            x2="10"
                            y2="18"
                            stroke="#fbfbfb"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <h1 class="footer-title">Liensdev</h1>
                </a>

                <div class="footer-center">
                    <div class="left-column">
                        <h2>Pages</h2>
                        <a href="/">Home</a>
                        <a href="">About</a>
                        <a href="/portfolio">Portfolio</a>
                        <a href="/contact">Contact</a>
                    </div>
                    <div class="right-column">
                        <h2>Utility</h2>
                        <a href="/styles">Styles</a>
                        <a href="/404">404</a>
                    </div>
                </div>

                <!--                 <div class="footer-right">
                    <h2>Contact</h2>

                    <div class="input-holder">
                        <input type="email" placeholder="Enter your email here">
                        <button class="email-send">Get in touch</button>
                    </div>
                </div> --><!-- 0f0f0f -->
            </div>
            <div class="footer-bottom">
                <a href="#">
                    <svg
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M24,2.5A21.52,21.52,0,0,0,5.15,34.36L2.5,45.5l11.14-2.65A21.5,21.5,0,1,0,24,2.5ZM13.25,12.27h5.86a1,1,0,0,1,1,1,10.4,10.4,0,0,0,.66,3.91,1.93,1.93,0,0,1-.66,2.44l-2.05,2a18.6,18.6,0,0,0,3.52,4.79A18.6,18.6,0,0,0,26.35,30l2-2.05c1-1,1.46-1,2.44-.66a10.4,10.4,0,0,0,3.91.66,1.05,1.05,0,0,1,1,1v5.86a1.05,1.05,0,0,1-1,1,23.68,23.68,0,0,1-15.64-6.84,23.6,23.6,0,0,1-6.84-15.64A1.07,1.07,0,0,1,13.25,12.27Z"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a href="#">
                    <svg
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                        id="Layer_2"
                        data-name="Layer 2"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.47,10.71a2,2,0,0,0-2,2h0V35.32a2,2,0,0,0,2,2H41.53a2,2,0,0,0,2-2h0V12.68a2,2,0,0,0-2-2H6.47Zm33.21,3.82L24,26.07,8.32,14.53"
                            ></path>
                        </g>
                    </svg>
                </a>
                <a href="#">
                    <svg
                        width="48px"
                        height="48px"
                        viewBox="0 0 48 48"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                    >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                            <circle
                                fill="#000000"
                                cx="15.64"
                                cy="13.46"
                                r=".75"
                            ></circle>
                            <line
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                x1="15.64"
                                x2="15.64"
                                y1="16.93"
                                y2="31.91"
                            ></line>
                            <path
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m33.11 31.91v-9.61a5.83 5.83 0 0 0-5.82-5.82 5.83 5.83 0 0 0-5.83 5.82v9.61"
                            ></path>
                            <line
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                x1="21.46"
                                x2="21.46"
                                y1="22.3"
                                y2="16.93"
                            ></line>
                            <path
                                fill="none"
                                stroke="#000000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m40.5 5.5h-33a2 2 0 0 0-2 2v33a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2v-33a2 2 0 0 0-2-2z"
                            ></path>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>

<style scoped lang="scss">
@import "@/assets/styles.scss";

footer {
    height: 100%;
    width: 100%;
    padding: 100px 0 60px;
    background-color: var(--color-dark);

    .footer-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
        .footer-top {
            display: flex;
            justify-content: space-between;
            gap: 100px;
            .footer-left {
                display: flex;
                gap: 5px;
                .footer-title {
                    color: var(--color-light);
                }
            }

            .footer-center {
                display: flex;
                justify-content: space-between;
                gap: 100px;

                h2 {
                    color: var(--color-light);
                    text-align: center;
                    margin-bottom: 10px;
                }

                a {
                    @include basic-btn(
                        var(--color-dark),
                        10px,
                        var(--color-light)
                    );
                    width: auto;
                }
            }

            .footer-right {
                h2 {
                    color: var(--color-light);
                    text-align: center;
                }

                .input-holder {
                    display: flex;
                    padding: 10px;
                    background-color: var(--color-light);
                    border-radius: 30px;
                    overflow: hidden;

                    input {
                        border: none;

                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }

                    .email-send {
                        @include basic-btn(
                            var(--color-dark),
                            5px 10px,
                            var(--color-light)
                        );
                        font-size: 18px;
                    }
                }
            }
        }
        .footer-bottom {
            display: flex;
            justify-content: center;
            gap: 25px;
            a {
                @include basic-btn(var(--color-light), 10px, var(--color-dark));
                width: 48px;
                border-radius: 50%;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 50px 0 60px;
        .footer-content {
            .footer-top {
                flex-direction: column;
                gap: 50px;
            }

            .footer-bottom {
                a {
                    @include basic-btn(
                        var(--color-light),
                        5px 15px,
                        var(--color-dark)
                    );
                    width: 32px;
                    border-radius: 100%;
                }
            }
        }
    }
}
</style>
