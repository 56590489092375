<script>
import NavBar from "@/components/global/NavBar.vue";
import Form from "@/components/Contact/Form.vue";
import Footer from "@/components/global/Footer.vue";

export default {
    components: {
        NavBar,
        Form,
        Footer,
    },
};
</script>

<template>
    <NavBar />
    <Form />
    <Footer />
</template>
